import * as React from "react";
import "./career-section.scss";
import {useTranslation} from "react-i18next";
import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import Container from "../Container/container";
import JobOffer from "./JobOffer/job-offer";
import {Link} from "gatsby-plugin-react-i18next";
import Space from "../Space/space";


const CareerSection = () => {
    const {t} = useTranslation();

    const jobOffers = [{
        title: ".NET Developer",
        description: "W ramach realizowanych przez nas projektów i współpracy z partnerami biznesowymi w zakresie rekrutacji, poszukujemy .NET Developerów do długoterminowej współpracy.",
        type: "Kontrakt B2B",
        salary: "70-100 zł netto",
        where: "zdalnie",
        ourOffer: `
            - benefity pozapłacowe
            - stawiamy na rozwój, istotne jest dla nas abyś czuł/-a że się rozwijasz i spełniasz zawodowo
        `,
        ourRequirements: `
            - min. 2 lata doświadczenia jako .NET Developer
            - bardzo dobra znajomość języka C#
            - znajomość EF Core, ASP.NET, DI, Git, CQRS
            - komunikatywny angielski
            `
    }, {
        title: "React Developer",
        description: "W ramach realizowanych przez nas projektów i współpracy z partnerami biznesowymi w zakresie rekrutacji, poszukujemy React Developerów do długoterminowej współpracy.",
        type: "Kontrakt B2B",
        salary: "70-100 zł netto",
        where: "zdalnie",
        ourOffer: `
            - benefity pozapłacowe
            - stawiamy na rozwój, istotne jest dla nas abyś czuł/-a że się rozwijasz i spełniasz zawodowo
        `,
        ourRequirements: `
            - min. 1,5 roku doświadczenia jako React Developer
            - bardzo dobra znajomość nowoczesnego JavaScript i CSS
            - znajomość dobrych praktyk programistycznych
            - znajomość React Hooks, Redux, SCSS
            - komunikatywny angielski
            `
    }
    ]

    return (
        <Section sectionId={"career"}>
            <Linolade/>
            <SectionHeader text={t('career.joinUs.title')} highlightedText={t('career.joinUs.highlightedText')}/>
            <Container>
                <p>{t('career.joinUs.description')}</p>
                <Space/>
                {jobOffers.map((offer: any, i: number) =>
                    <JobOffer offer={offer}/>
                )}
                <Space/>
                <p>You can apply by sending your CV on:</p>
                <Link className="career-section_link" to={"mailto:career@markana.it"}>career@markana.it</Link>
            </Container>
        </Section>
    );
}

export default CareerSection;