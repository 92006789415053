import * as React from "react";
import "./job-offer.scss";

interface JobOfferType {
    title: string;
    salary: string;
    description: string;
    type: string;
    where: string;
    ourOffer: string;
    ourRequirements: string;
}

const JobOffer = (props: { offer: JobOfferType }) => {
    return (
        <div className="job-offer">
            <details>
                <summary>
                    {props.offer.title}
                    <div className="job-offer_line"></div>
                </summary>
                <strong>O stanowisku</strong>
                <p>{props.offer.description}</p>
                <p><strong>Wynagrodzenie: </strong>{props.offer.salary}</p>
                <p><strong>Lokalizacja: </strong>{props.offer.where}</p>
                <p><strong>Typ umowy: </strong>{props.offer.type}</p>
                <p>
                    <strong>Nasza oferta</strong>
                    {props.offer.ourOffer}
                </p>
                <p>
                    <strong>Nasze wymagania</strong>
                    {props.offer.ourRequirements}
                </p>
            </details>
        </div>
    )
}

export default JobOffer;